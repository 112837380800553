.button_wrapper {
  text-transform: uppercase;
  cursor: pointer;
  user-select: none;
  width: 351px;
  height: 27px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background-image: url('../../assets/img/angle.svg');
    background-size: cover;
    background-repeat: no-repeat;
    left: -6.5px;
    top: -6.5px;
  }
  &::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background-image: url('../../assets/img/angle_simple.svg');
    background-size: cover;
    background-repeat: no-repeat;
    right: -6px;
    top: -6px;
    transform: rotate(180deg);
  }
  .cool_button {
    width: 351px;
    height: 27px;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      background-image: url('../../assets/img/angle_simple.svg');
      background-size: cover;
      background-repeat: no-repeat;
      left: -6px;
      bottom: -6px;
    }
    &::after {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      background-image: url('../../assets/img/angle_simple.svg');
      background-size: cover;
      background-repeat: no-repeat;
      right: -6px;
      bottom: -6px;
      transform: rotate(-90deg);
    }
    .button {
      position: relative;
      border: none;
      background: none;
      outline: none;
      color: black;
      mix-blend-mode: screen;
      text-align: center;
      font-weight: 700;
      font-size: 12px;
      line-height: 27px;
    }

    .button::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: white;
      width: 351px;
      height: 27px;
      mix-blend-mode: color-burn;
    }
  }
}

.wrong_popup {
  text-transform: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  .icon {
    width: 99.49px;
    height: 79.26px;
    margin: 0 0 16px 0;
    background-image: url('../../assets/img/wrong.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  .under_icon {
    width: 131.25px;
    height: 5.43px;
    margin: 0 0 30px 0;
    background: radial-gradient(
      50% 50% at 50% 50%,
      #ce2727 0%,
      rgba(206, 39, 39, 0) 100%
    );
    filter: blur(2px);
  }
  .title {
    font-family: 'IBM Plex Mono';
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    margin: 0 0 8px 0;

    text-transform: uppercase;
  }
  .paragraph {
    margin: 0 0 24px 0;
    font-family: 'IBM Plex Mono';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    a {
      color: #0f73ef;
      text-decoration: underline #0f73ef;
    }
  }
}

@media (max-width: 500px) {
  .button_wrapper {
    width: 279px;
    .cool_button {
      width: 279px;
      .button::before {
        width: 279px;
      }
    }
  }
  .metamask_popup {
    .icon {
      width: 75.11px;
      height: 59.84px;
      margin: 0 0 19px 0;
    }
    .under_icon {
      margin: 0 0 24px 0;
    }
    .title {
      margin: 0 0 12px 0;
    }
    .paragraph {
      margin: 0 0 235x 0;
    }
  }
}
