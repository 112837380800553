.button_wrapper {
  text-transform: uppercase;
  cursor: pointer;
  user-select: none;
  width: 207px;
  height: 27px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background-image: url('../../assets/img/angle.svg');
    background-size: cover;
    background-repeat: no-repeat;
    left: -6.5px;
    top: -6.5px;
  }
  &::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background-image: url('../../assets/img/angle_simple.svg');
    background-size: cover;
    background-repeat: no-repeat;
    right: -6px;
    top: -6px;
    transform: rotate(180deg);
  }
  .cool_button {
    width: 207px;
    height: 27px;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      background-image: url('../../assets/img/angle_simple.svg');
      background-size: cover;
      background-repeat: no-repeat;
      left: -6px;
      bottom: -6px;
    }
    &::after {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      background-image: url('../../assets/img/angle_simple.svg');
      background-size: cover;
      background-repeat: no-repeat;
      right: -6px;
      bottom: -6px;
      transform: rotate(-90deg);
    }
    .button {
      position: relative;
      border: none;
      background: none;
      outline: none;
      color: black;
      mix-blend-mode: screen;
      text-align: center;
      font-weight: 700;
      font-size: 12px;
      line-height: 27px;
    }

    .button::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: white;
      width: 207px;
      height: 27px;
      mix-blend-mode: color-burn;
    }
  }
}

.metamask_popup {
  text-transform: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  .icon {
    width: 97.05px;
    height: 97.05px;
    margin: 0 0 31px 0;
    background-image: url('../../assets/img/metamask_popup.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  .metamask {
    display: none;
  }
  .paragraph {
    max-width: 408px;
    margin: 0 0 40px 0;
    font-family: 'IBM Plex Mono';
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    span {
      display: block;
      text-align: center;
    }
  }
  .buttons {
    display: flex;
    align-items: center;
    gap: 36px;
    .cancel {
      cursor: pointer;
      user-select: none;
      text-transform: uppercase;
      width: 119px;
      font-family: 'IBM Plex Mono';
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
    }
  }
}

@media (max-width: 500px) {
  .button_wrapper {
    width: 266px;
    .cool_button {
      width: 266px;
      .button::before {
        width: 266px;
      }
    }
  }
  .metamask_popup {
    .icon {
      width: 78.26px;
      height: 78.26px;
      margin: 0 0 13px 0;
    }
    .metamask {
      display: block;
      margin: 0 0 35px 0;
      font-family: 'IBM Plex Mono';
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
    }
    .paragraph {
      max-width: 313px;
      margin: 0 0 42px 0;
      font-size: 16px;
      span {
        display: inline;
      }
    }
    .buttons {
      display: flex;
      flex-direction: column-reverse;
      gap: 23px;
    }
  }
}
