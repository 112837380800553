.modal {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  mix-blend-mode: normal;
}
.modalWrapper {
  width: 488px;
  height: 428px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    background-image: url('../../assets/img/angle.svg');
    background-size: contain;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    z-index: 1;
  }
  &::after {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    background-image: url('../../assets/img/angle_simple.svg');
    background-size: contain;
    background-repeat: no-repeat;
    top: 0;
    right: 0;
    z-index: 1;
    transform: rotate(180deg);
  }
}
.modalBody {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 488px;
  height: 428px;
  color: white;
  backdrop-filter: blur(12px);
  background: rgba(0, 0, 0, 0.1);
  // border: 0.1px solid #ffffff;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    background-image: url('../../assets/img/angle_simple.svg');
    background-size: contain;
    background-repeat: no-repeat;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
  &::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background-image: url('../../assets/img/triangle.svg');
    background-size: contain;
    background-repeat: no-repeat;
    bottom: 0;
    right: 0;
    z-index: 1;
  }
}
.close {
  cursor: pointer;
  user-select: none;
  width: 20px;
  height: 20px;
  background-image: url('../../assets/img/close.svg');
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  z-index: 1;
  top: 16px;
  right: 16px;
}

@media (max-width: 500px) {
  .modalWrapper {
    width: 343px;
    height: 421px;
  }
  .modalBody {
    width: 343px;
    height: 421px;
  }
}
