.error_popup {
  text-transform: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    font-family: 'IBM Plex Mono';
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0px;
    text-align: center;

    margin: 0 0 8px 0;

    text-transform: uppercase;
  }
  .paragraph {
    margin: 0 0 24px 0;
    font-family: 'IBM Plex Mono';
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
  }
  .browser_text{
    font-family: 'IBM Plex Mono';
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
    margin-bottom: 16px;
  }
  .browsers_wrapper{
    display: flex;
    gap: 16px;
    .browsers_item{
      position: relative;
      padding: 16px;
    }
    .browsers_item::before{
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      background-image: url('./assets/corner.svg');
      background-size: contain;
      background-repeat: no-repeat;
      top: 0;
      left: 0;
      z-index: 1;
    }
    .browsers_item::after{
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      background-image: url('./assets/corner.svg');
      background-size: contain;
      background-repeat: no-repeat;
      transform: rotate(90deg);
      top: 0;
      right: 0;
      z-index: 1;
    }
    .browsers_content{
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      align-items: center;
    }
    .browsers_content::before{
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      background-image: url('./assets/corner.svg');
      background-size: contain;
      background-repeat: no-repeat;
      bottom: 0;
      left: 0;
      transform: rotate(-90deg);
      z-index: 1;
    }
    .browsers_content::after{
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      background-image: url('./assets/corner1.svg');
      background-size: contain;
      background-repeat: no-repeat;
      bottom: 0;
      right: 0;
      z-index: 1;
    }
  }
}

@media (max-width: 500px) {
  
}
