.background {
  height: 100vh;
  color: #ffffff;
  font-size: 1.041vw;
  position: absolute;
  background: transparent;
  left: 0;
  right: 0;
  .container {
    z-index: 2;
    height: calc(100vh - 62px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 36px 30px 26px 30px;
    .top {
      display: flex;
      justify-content: space-between;
      .company {
        line-height: 16px;
        span {
          font-weight: 500;
        }
      }
      .nft_pass {
        padding: 2px 0 0 0;
        .key {
          cursor: pointer;
          user-select: none;
          margin: 0 0 10px 0;
          line-height: 16px;
          span {
            font-weight: 500;
          }
        }
        .value {
          font-size: 0.55vw;
          line-height: 16px;
          color: rgba(255, 255, 255, 0.66);
        }
      }
    }
  }
  .middle {
    display: flex;
    justify-content: space-between;
    .boarding {
      display: flex;
      gap: 0.9vw;
      .keys {
        & div:nth-child(2) {
          font-size: 0.55vw;
          line-height: 16px;
          color: rgba(255, 255, 255, 0.66);
          margin: 10px 0 0 0;
        }
      }
      .values {
        & div:nth-child(2) {
          font-size: 0.34vw;
          line-height: 12px;
          letter-spacing: 0.04em;
          margin: 13px 0 0 0;
        }
      }
    }
    .features {
      & div:nth-child(2) {
        font-size: 0.55vw;
        line-height: 16px;
        color: rgba(255, 255, 255, 0.66);
        margin: 10px 0 0 0;
      }
    }
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .statistics_left,
    .statistics_right {
      display: flex;
      gap: 10px;
      font-size: 0.75vw;
      line-height: 12px;
      font-weight: 500;
      .keys,
      .progress,
      .values {
        & div:not(:last-child) {
          margin: 0 0 10px 0;
        }
      }
    }
    .statistics_left {
      .keys {
        text-align: right;
      }
      .progress {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0 0 0 10px;
        & div {
          height: 5px;
          background-color: #ffffff;
          border: 1px solid #ffffff;
        }
        & div:nth-child(1),
        & div:nth-child(3) {
          width: 1.18vw;
        }
        & div:nth-child(2) {
          width: 3.05vw;
        }
      }
    }
    .statistics_right {
      .values {
        text-align: right;
      }
      .progress {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        margin: 0 10px 0 0;
        & div {
          height: 5px;
          background-color: #ffffff;
          border: 1px solid #ffffff;
        }
        & div:nth-child(1) {
          width: 1.875vw;
        }
        & div:nth-child(2) {
          width: 2.77vw;
        }
        & div:nth-child(3) {
          width: 3.95vw;
        }
      }
    }
    .metamask_login {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      line-height: 12px;
      font-weight: 500;
      cursor: pointer;
      .date {
        margin: 0 0 10px 0;
        font-size: 0.34vw;
      }
      .login {
        user-select: none;
        margin: 0 0 15px 0;
        font-size: 0.55vw;
      }
      .line {
        width: 1px;
        height: 45.86px;
        margin: 0 0 25px 0;
        background-image: url('../../assets/img/logo-line.svg');
        background-repeat: no-repeat;
        background-position: center;
      }
      .metamask {
        margin: 0 0 37px 0;
        font-weight: 600;
        line-height: 17px;
      }
      .logo {
        cursor: pointer;
        user-select: none;
        width: 24px;
        height: 24px;
        background-image: url('../../assets/img/metamask.svg');
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
}

@media (max-width: 480px) {
  .background {
    font-size: 3.84615384615vw;
    .container {
      padding: 50px 18px 0 18px;
      .top {
        display: flex;
        justify-content: space-between;

        .nft_pass {
          padding: 0;
          .value {
            font-size: 2.05128205128vw;
            text-align: right;
          }
        }
      }
      .middle {
        display: none;
      }
      .bottom {
        padding: 0 0 50px 0;
        .statistics_left,
        .statistics_right {
          font-size: 1.84615384615vw;
          .keys,
          .progress,
          .values {
            & div:not(:last-child) {
              margin: 0 0 7px 0;
            }
          }
        }
        .statistics_left {
          .keys {
            text-align: left;
          }
          .progress {
            justify-content: space-evenly;
            & div {
              height: 5px;
              border: none;
            }
            & div:nth-child(1),
            & div:nth-child(3) {
              width: 3.84615384615vw;
            }
            & div:nth-child(2) {
              width: 10.2564102564vw;
            }
          }
        }
        .statistics_right {
          .keys {
            text-align: right;
          }
          .progress {
            justify-content: space-evenly;
            & div {
              height: 5px;
              border: none;
            }
            & div:nth-child(1) {
              width: 6.15384615385vw;
            }
            & div:nth-child(2) {
              width: 9.23076923077vw;
            }
            & div:nth-child(3) {
              width: 13.0769230769vw;
            }
          }
        }
        .metamask_login {
          position: absolute;
          bottom: 162px;
          left: 50%;
          transform: translate(-50%, 0);
          .date {
            margin: 0 0 1px 0;
            font-size: 1.28205128205vw;
          }
          .login {
            margin: 0 0 5px 0;
            font-size: 2.05128205128vw;
          }
          .line {
            margin: 0 0 5px 0;
          }
          .metamask {
            margin: 0 0 6px 0;
            font-size: 3.84615384615vw;
          }
        }
      }
    }
  }
}
