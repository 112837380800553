body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 0.9375vw;
  line-height: 20px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #000000;
  overflow-x: hidden;
}
.content {
  height: 100vh;
  justify-content: center;
}
canvas {
  height: 100vh !important;
}
@media (max-width: 480px) {
  body {
    font-size: 3.2vw;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ibm-plex-mono-regular - latin */
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/ibm-plex-mono-v12-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/ibm-plex-mono-v12-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/ibm-plex-mono-v12-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./assets/fonts/ibm-plex-mono-v12-latin-regular.woff') format('woff'),
    /* Modern Browsers */
      url('./assets/fonts/ibm-plex-mono-v12-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/ibm-plex-mono-v12-latin-regular.svg#IBMPlexMono')
      format('svg');
  /* Legacy iOS */
}

/* ibm-plex-mono-500 - latin */
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/ibm-plex-mono-v12-latin-500.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/ibm-plex-mono-v12-latin-500.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/ibm-plex-mono-v12-latin-500.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./assets/fonts/ibm-plex-mono-v12-latin-500.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/ibm-plex-mono-v12-latin-500.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/ibm-plex-mono-v12-latin-500.svg#IBMPlexMono')
      format('svg');
  /* Legacy iOS */
}

/* ibm-plex-mono-600 - latin */
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/ibm-plex-mono-v12-latin-600.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/ibm-plex-mono-v12-latin-600.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/ibm-plex-mono-v12-latin-600.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./assets/fonts/ibm-plex-mono-v12-latin-600.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/ibm-plex-mono-v12-latin-600.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/ibm-plex-mono-v12-latin-600.svg#IBMPlexMono')
      format('svg');
  /* Legacy iOS */
}

/* ibm-plex-mono-700 - latin */
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/ibm-plex-mono-v12-latin-700.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/ibm-plex-mono-v12-latin-700.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/ibm-plex-mono-v12-latin-700.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./assets/fonts/ibm-plex-mono-v12-latin-700.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/ibm-plex-mono-v12-latin-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/ibm-plex-mono-v12-latin-700.svg#IBMPlexMono')
      format('svg');
  /* Legacy iOS */
}

/* space-mono-regular */
@font-face {
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/SpaceMono-Regular.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/SpaceMono-Regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/SpaceMono-Regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./assets/fonts/SpaceMono-Regular.woff')
      format('woff'),
    /* Modern Browsers */ url('./assets/fonts/SpaceMono-Regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/SpaceMono-Regular.svg#SpaceMono') format('svg');
  /* Legacy iOS */
}

/* space-grotesk-300 */
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/SpaceGrotesk-Light.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/SpaceGrotesk-Light.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/SpaceGrotesk-Light.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./assets/fonts/SpaceGrotesk-Light.woff')
      format('woff'),
    /* Modern Browsers */ url('./assets/fonts/SpaceGrotesk-Light.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/SpaceGrotesk-Light.svg#SpaceMono') format('svg');
  /* Legacy iOS */
}

/* space-grotesk-regular */
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/SpaceGrotesk-Regular.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/SpaceGrotesk-Regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/SpaceGrotesk-Regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */ url('./assets/fonts/SpaceGrotesk-Regular.woff')
      format('woff'),
    /* Modern Browsers */ url('./assets/fonts/SpaceGrotesk-Regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/SpaceGrotesk-Regular.svg#SpaceMono') format('svg');
  /* Legacy iOS */
}

/* space-grotesk-500 */
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/SpaceGrotesk-Medium.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/SpaceGrotesk-Medium.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/SpaceGrotesk-Medium.woff2')
      format('woff2'),
    /* Super Modern Browsers */ url('./assets/fonts/SpaceGrotesk-Medium.woff')
      format('woff'),
    /* Modern Browsers */ url('./assets/fonts/SpaceGrotesk-Medium.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/SpaceGrotesk-Medium.svg#SpaceMono') format('svg');
  /* Legacy iOS */
}

a {
  color: #000000;
  text-decoration: none;
  &:hover {
    opacity: 0.7;
  }
}
